import { ReactElement } from "react"
import { GetFilterButton } from "@components/buttons/FilterButton"
import { GamePlatform } from "@interfaces/games"

export interface LibraryProps {
    title: string,
    categories: GamePlatform[],
    children: ReactElement[],
}

export function Library({ title, categories, children }: LibraryProps) {

    return (
        <div className="w-full">
            <div className="pt-8 pb-2 whitespace-nowrap text-2xl font-bold md:text-4xl md:font-extrabold text-center
             text-white">{title}</div>
            <div className="w-full p-4 flex flex-row items-center justify-center gap-4">
                {categories.map((category: GamePlatform, index: number) => (
                    <GetFilterButton key={index} platform={category} />
                ))}
            </div>
            <div className="w-full flex flex-row justify-center pt-4 px-2 pb-10 gap-4">
                {children}
            </div>
        </div>
    )
}

export function VerticalLibrary({ title, categories, children }: LibraryProps) {

    return (
        <div className="w-full">
            <div className="pt-8 pb-2 whitespace-nowrap text-2xl font-bold md:text-4xl md:font-extrabold text-center
             text-white">{title}</div>
            <div className="w-full p-4 flex flex-row items-center justify-center gap-4">
                {categories.map((category: GamePlatform, index: number) => (
                    <GetFilterButton key={index} platform={category} />
                ))}
            </div>
            <div className="w-full flex flex-col justify-center pt-4 px-2 pb-10 gap-4">
                {children}
            </div>
        </div>
    )
}
