import { useSelector } from "react-redux";
import { GameInformation, GamePages, GamePlatform, GameStatus, ImplementedPlatform } from "@interfaces/games";
import { GameLibraryFilters, PlatformFilters } from "@services/slice/gameLibrary";
import { RootState } from "@store";
import { platform } from "os";


export function useGetGameInfo(id: string | undefined): GameInformation | undefined {
    const gamePage: GamePages = useSelector((state: RootState) => state.gamePage)

    if (id !== undefined && id in gamePage) {
        return gamePage[id]
    }

    return undefined
}

export function useGetGameInfoList(): GameInformation[] {
    return Object.values(useSelector((state: RootState) => state.gamePage))
}

export function useGetGameInfoListByFilters(filters: GameLibraryFilters): GameInformation[] {
    let filteredGames = Object.values(useSelector((state: RootState) => state.gamePage))

    if (filters.platforms.browser) {
        filteredGames = filteredGames.filter((game: GameInformation) =>
            game.platforms.find((platform: ImplementedPlatform) => platform.platform === GamePlatform.BROWSER))
    }

    if (filters.platforms.windows) {
        filteredGames = filteredGames.filter((game: GameInformation) =>
            game.platforms.find((platform: ImplementedPlatform) => platform.platform === GamePlatform.WINDOWS))
    }

    if (filters.platforms.android) {
        filteredGames = filteredGames.filter((game: GameInformation) =>
            game.platforms.find((platform: ImplementedPlatform) => platform.platform === GamePlatform.ANDROID))
    }

    if (filters.platforms.ios) {
        filteredGames = filteredGames.filter((game: GameInformation) =>
            game.platforms.find((platform: ImplementedPlatform) => platform.platform === GamePlatform.IOS))
    }

    if (filters.other.jams) {
        filteredGames = filteredGames.filter((game: GameInformation) =>
            game.status === GameStatus.JAM)
    }
    else {
        filteredGames = filteredGames.filter((game: GameInformation) =>
            game.status !== GameStatus.JAM)
    }

    return filteredGames
}
