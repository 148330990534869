import { createSlice, PayloadAction } from "@reduxjs/toolkit"


export interface PlatformFilters {
    browser: boolean,
    windows: boolean,
    ios: boolean,
    android: boolean,
    home: boolean,
}

export interface OtherFilters {
    jams: boolean
}

export interface GameLibraryFilters {
    platforms: PlatformFilters,
    other: OtherFilters
}

export interface GameLibrary {
    filters: GameLibraryFilters
}

const initialState: GameLibrary = {
    filters: {
        platforms: {
            browser: true,
            windows: false,
            ios: false,
            android: false,
            home: false,
        },
        other: {
            jams: false,
        }
    }
}

export const gameLibrarySlice = createSlice({
    name: "gameLibrary",
    initialState,
    reducers: {
        setBrowserFilter: (state, action: PayloadAction<boolean>) => {
            state.filters.platforms.browser = action.payload
        },
        setWindowsFilter: (state, action: PayloadAction<boolean>) => {
            state.filters.platforms.windows = action.payload
        },
        setIosFilter: (state, action: PayloadAction<boolean>) => {
            state.filters.platforms.ios = action.payload
        },
        setAndroidFilter: (state, action: PayloadAction<boolean>) => {
            state.filters.platforms.android = action.payload
        },
        setHomeFilter: (state, action: PayloadAction<boolean>) => {
            state.filters.platforms.home = action.payload
        },
    },
})

export const {
    setAndroidFilter,
    setBrowserFilter,
    setHomeFilter,
    setIosFilter,
    setWindowsFilter,
} = gameLibrarySlice.actions
