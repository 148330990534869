export interface NewsButtonProps {
    link: string
    label: string
    prime?: boolean
}

export function NewsButton({ link, label, prime }: NewsButtonProps) {

    let backgroundColour = prime ? "bg-dgs-blue-dark-2" : "bg-dgs-night"

    return (
        <a className='flex items-center justify-center' href={link}>
            <div className={`max-w-min h-12 p-4 rounded-full border-2 flex items-center justify-center
            hover:bg-dgs-cyan border-white ${backgroundColour}`}>
                <div className='text-lg font-bold text-center text-dgs-yellow whitespace-nowrap'>
                    {label}
                </div>
            </div>
        </a>
    )
}

export function LargeNewsButton({ link, label, prime }: NewsButtonProps) {

    let backgroundColour = prime ? "bg-dgs-blue-dark-2" : "bg-dgs-night"

    return (
        <a href={link} className={`rounded-full border-2 flex items-center justify-center py-4 px-8
        hover:bg-dgs-cyan border-white ${backgroundColour}`}>
            <div className='text-lg lg:text-2xl font-bold text-center text-dgs-yellow'>
                {label}
            </div>
        </a>
    )
}
