import { MouseEventHandler } from "react"
import { useDispatch } from "react-redux";

import { GamePlatform } from "@interfaces/games";
import { useGetLibraryFilters } from "@services/hooks/gameLibrary";
import { setAndroidFilter, setBrowserFilter, setIosFilter, setWindowsFilter } from "@services/slice/gameLibrary";


export interface FilterButtonProps {
    title: string
    prime: boolean
    onClick: MouseEventHandler
}

export function FilterButton({ title, prime, onClick }: FilterButtonProps) {

    const backGround = prime ? "bg-dgs-cyan" : "bg-dgs-blue-dark-2"
    const text = prime ? "text-dgs-blue-dark-2" : "text-dgs-yellow"

    return (
        <button className={`${backGround} hover:xl:bg-dgs-cyan
        rounded-full flex flex-row items-center justify-end 
        gap-2 px-3 py-1 lg:px-4 lg:py-2`} onClick={onClick}>
            <div className={`flex-none text-sm md:text font-semibold text-center text-nowrap
             ${text} hover:xl:text-dgs-yellow`}>
                {title}
            </div>
        </button>
    )
}

export interface PublishersLinkProps {
    title: string
    link: string
}

export function BrowserFilterButton() {
    const filter = useGetLibraryFilters().platforms.browser
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(setBrowserFilter(!filter))
    }

    return (
        <FilterButton title="Web" prime={filter} onClick={onClick} />
    )
}

export function WindowsFilterButton() {
    const filter = useGetLibraryFilters().platforms.windows
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(setWindowsFilter(!filter))
    }

    return (
        <FilterButton title="PC" prime={filter} onClick={onClick} />
    )
}

export function AndroidFilterButton() {
    const filter = useGetLibraryFilters().platforms.android
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(setAndroidFilter(!filter))
    }

    return (
        <FilterButton title="Android" prime={filter} onClick={onClick} />
    )
}

export function IosFilterButton() {
    const filter = useGetLibraryFilters().platforms.ios
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(setIosFilter(!filter))
    }

    return (
        <FilterButton title="iOS" prime={filter} onClick={onClick} />
    )
}

export interface GetFilterButtonProps {
    platform: GamePlatform
}

export function GetFilterButton({ platform }: GetFilterButtonProps) {
    switch (platform) {
        case GamePlatform.BROWSER:
            return <BrowserFilterButton />
        case GamePlatform.WINDOWS:
            return <WindowsFilterButton />
        case GamePlatform.ANDROID:
            return <AndroidFilterButton />
        case GamePlatform.IOS:
            return <IosFilterButton />
        default:
            return <BrowserFilterButton />
    }
}
