import { createSlice } from "@reduxjs/toolkit"
import { GamePages } from "@interfaces/games"
import * as games from "@services/api/tempApi"


const initialState: GamePages = {
    "weather-or-not": games.WEATHER_OR_NOT_INFO,
    "demolition-force": games.DEMOLITION_FORCE_INFO,
    "voyage-to-nowhere": games.VOYAGE_TO_NOWHERE_INFO,
    "defend-your-honour": games.DEFEND_YOUR_HONOUR_INFO,
}

export const gamePageSlice = createSlice({
    name: "gamePage",
    initialState,
    reducers: {},
})
