
'use client';

import { Carousel } from 'flowbite-react';

import { LargeNewsButton, NewsButton } from '@components/buttons/NewsButton';
import { modulo } from '@utils/conversions';

export interface Slide {
    src: string,
    title: string,
    description: string,
    button: string,
    link: string,
}

export interface SlideShowProps {
    slides: Slide[],
}

export function SlideShow({ slides }: SlideShowProps) {
    return (
        <div className="h-115 md:h-115">
            <Carousel slideInterval={5000}>
                {slides.map((slide: Slide, index: number) => (
                    <div key={index} className="flex h-full items-center justify-center rounded-none md:gap-2">
                        <div className='relative h-full w-0 lg:w-20 xl:w-40 flex-none flex items-center justify-center rounded-r-md overflow-hidden bg-slate-700'>
                            <div className='absolute z-30 h-full w-full opacity-70 bg-black'></div>
                            <img src={slides[modulo((index - 1), slides.length)].src} alt="photo..." className='h-full object-cover'></img>
                        </div>
                        <div className='relative h-full flex rounded-md overflow-hidden flex-grow bg-slate-500'>
                            <div className='absolute z-30 bottom-0 flex w-full bg-gradient-to-t from-dgs-night'>
                                <div className='p-16 sm:p-20 w-full flex flex-col sm:pr-10 lg:pr-96 gap-10'>
                                    <div className='lg:pr-20 xl:pr-48'>
                                        <div className=''>
                                            <div className='text-3xl md:text-4xl xl:text-6xl text-center font-bold md:text-left text-white'>{slide.title}</div>
                                            <div className='text-lg md:text-xl xl:text-3xl font-semibold text-left hidden md:block text-gray-400'>{slide.description}</div>
                                        </div>
                                    </div>
                                    <div className='md:hidden flex flex-row gap-4 items-center justify-center'>
                                        <NewsButton link={slide.link} label={slide.button} prime={true} />
                                        <NewsButton link="/#page-games" label="All Games" />
                                    </div>
                                    <div className='hidden md:flex'>
                                        <div className='flex flex-row gap-4 items-center justify-center'>
                                            <LargeNewsButton link={slide.link} label={slide.button} prime={true} />
                                            <LargeNewsButton link="/#page-games" label="All Games" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src={slide.src} alt="photo..." className='h-full w-full object-cover'></img>
                        </div>
                        <div className='relative h-full w-0 lg:w-20 xl:w-40 flex-none flex items-center justify-center rounded-l-md overflow-hidden bg-slate-900'>
                            <div className='absolute z-30 h-full w-full opacity-70 bg-black'></div>
                            <img src={slides[(index + 1) % slides.length].src} alt="photo..." className='h-full object-cover'></img>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
}
