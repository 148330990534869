import { VerticalLibrary } from "@components/containers/Library";
import { DynamicGameProfile } from "@components/profiles/GameProfile";
import { GameInformation, GamePlatform } from "@interfaces/games";
import { useGetLibraryFilters } from "@services/hooks/gameLibrary";
import { useGetGameInfoListByFilters } from "@services/hooks/gamePage"
import { GameLibraryFilters } from "@services/slice/gameLibrary";


export interface GameLibraryProps {
    gameReleases: GameInformation[]
    gameJams: GameInformation[]
}

export function GameLibrary({ gameReleases, gameJams }: GameLibraryProps) {
    return (
        <section id="page-games" className="w-full">
            <VerticalLibrary title="OUR GAMES"
                categories={[GamePlatform.BROWSER, GamePlatform.WINDOWS, GamePlatform.IOS, GamePlatform.ANDROID]}>
                <div className="w-full flex flex-col justify-center">
                    <div className="min-w-min px-4 text-2xl text-center font-bold text-white">Releases</div>
                    <div className="w-full flex flex-row justify-center pt-4 px-2 pb-10 gap-4 overflow-x-auto">
                        {gameReleases.map((game: GameInformation, index: number) => (
                            <DynamicGameProfile key={index} link={`/games/${game.id}`}
                                src={game.cover} title={game.title} description={game.summary} status={game.status} />
                        ))}
                    </div>
                </div>
                <div className="w-full flex flex-col justify-center">
                    <div className="min-w-min px-4 text-2xl text-center font-bold text-white">Game Jams</div>
                    <div className="w-full flex flex-row justify-center pt-4 px-2 pb-10 gap-4 overflow-x-auto">
                        {gameJams.map((game: GameInformation, index: number) => (
                            <DynamicGameProfile key={index} link={`/games/${game.id}`}
                                src={game.cover} title={game.title} description={game.summary} status={game.status} />
                        ))}
                    </div>
                </div>
            </VerticalLibrary>
        </section >
    )
}

export function DynamicGameLibrary() {

    const releasesFilters = useGetLibraryFilters()
    const jamsFilters = useGetLibraryFilters()

    const newJamsFilters: GameLibraryFilters = {
        platforms: jamsFilters.platforms,
        other: {
            jams: true
        }
    }

    const gameReleases = useGetGameInfoListByFilters(releasesFilters)
    const gameJams = useGetGameInfoListByFilters(newJamsFilters)

    return (
        <GameLibrary gameReleases={gameReleases} gameJams={gameJams} />
    )
}
