import { DynamicGameLibrary } from "@components/sections/GameLibrary"
import { PageFooter } from "@components/sections/PageFooter"
import { DynamicPageHeader } from "@components/sections/PageHeader"
import { PageBanner } from "@components/sections/PageBanner"
import { TeamList } from "@components/sections/TeamList"
import PHOTO_2 from "@static/won/posters/won_poster_2_v006_plain.jpg";


export function Games() {

    return (
        <div className="min-h-screen bg-gradient-to-br from-slate-800 to-black">
            <DynamicPageHeader />
            <div className="">
                <PageBanner src={PHOTO_2} />
                <DynamicGameLibrary />
                <TeamList />
                <PageFooter />
            </div>
        </div>
    )
}
